footer {
  background: #002762;
}

.filter-box {
  margin-top: 5em;
}

.filter-box .form-control {
  height: 45px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  color: #cccccc;
  text-align: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  line-height: 33px;
  margin-top: 0;
}

.filter-box .css-yk16xz-control {
  height: 45px !important;
}

.text {
  color: #6c757d !important;
  margin: 10px auto !important;
  text-align: center !important;
  font-size: 15px !important;
  max-width: 600px !important;
  position: relative !important;
}

.iconBox {
  width: 50px;
  height: 50px;
  padding: 30px;
  border-radius: 50%;
  background-color: #1fd88b !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text::before {
  content: "" !important;
  display: block !important;
  width: 80px !important;
  height: 2px !important;
  background-color: #6c757d !important;
  position: relative;
  margin-left: 8em !important;
  top: 11px !important;
}

.text::after {
  content: "" !important;
  display: block !important;
  width: 80px !important;
  height: 2px !important;
  background-color: #6c757d !important;
  margin-right: 8em !important;
  margin-top: -9px !important;
}

.filter-box .btn {
  background: #fddd01;
  height: 45px;
  border-radius: 4px;
  font-size: 14px;
  color: #000000;
}

.filter-box .btn:hover,
.filter-box .btn:active,
.filter-box .btn:focus {
  background: #fddd01;
  color: #000000;
}

.list-box {
    border-radius:  22px;
}
