* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: auto;
  font-family: "Poppins Regular";
  font-size: 12px;
  color: #000000;
  background: #f8f8f8;
  overflow-x: hidden;
}

.small {
  font-size: 10px;
}

/** Typograph **/
a,
a:hover,
a:active,
a:focus {
  text-decoration-color: none;
  text-decoration: none;
  outline: none;
}

.text-head {
  font-size: 72px;
  font-family: "Poppins Bold";
  line-height: 72px;
}

.text-justify {
  text-align: justify !important;
}

.text-title {
  font-size: 28px;
  font-family: "Poppins Medium";
  word-wrap: break-word;
}

.text-sub-title {
  font-size: 22px;
  font-family: "Poppins Regular";
  line-height: 28px;
  word-wrap: break-word;
}

.text-normal {
  font-size: 15px;
  line-height: calc(14px * 1.5px);
  word-wrap: break-word;
}

.text-small {
  font-size: 12px;
  line-height: calc(10px * 1.5px);
  word-wrap: break-word;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/** font **/

.font-bold {
  font-family: "Poppins Bold";
}

.font-regular {
  font-family: "Poppins Regular";
}

.font-medium {
  font-family: "Poppins Medium";
}

.font-light {
  font-family: "Poppins Light";
}

/** Color **/

.color-blue-dark {
  color: #3c8dbc;
}

.color-grey {
  color: #5e5e5e;
}

.color-white {
  color: #ededed;
}

.color-black {
  color: #000000;
}

/** Background **/

.background-blue {
  background-color: #078ece;
}

.background-grey {
  background-color: #eff3f6;
}

.background-white {
  background-color: #ffffff;
}

/** Input **/

textarea {
  resize: none;
}

.form-control {
  height: 45px !important;
  border-radius: 2px;
  margin: 3px 0;
  font-size: 11px;
  color: #1f1f1f !important;
  border: 1px solid #ededed;
  background: #ffffff;
  font-family: "Poppins Medium";
  box-shadow: none;
  outline: none;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  border: 2px solid #3c8dbc !important;
  height: 45px !important;
}

.form-control.phone-input {
  width: 70% !important;
}

.cursor {
  cursor: pointer;
}

.form-control:focus,
.form-control:active {
  border: 2px solid #078ece;
  box-shadow: none;
  outline: none;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn {
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

/** button **/
.btn-primary {
  background: #135465;
  color: #ffffff;
  border-radius: 0;
  border: none;
  outline: none;
  font-family: "Poppins Medium";
  font-size: 12px;
}
.btn-success {
  color: #ffffff;
  border-radius: 0;
  border: none;
  outline: none;
  font-family: "Poppins Medium";
  font-size: 14px;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: #135465;
  border: none;
  outline: none;
  color: #fff;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 3px 7px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}


.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.btn-paggination {
  width: 40px;
  height: 40px;
  border: 2px solid #3b3b3b !important;
  border-radius: 50%;
}

.btn-paggination:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: all !important;
}