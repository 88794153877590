/* Admin Layout Css */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrapper .admin-navbar {
  background: #3c8dbc;
  height: 50px;
  z-index: 10;
  color: #ffffff;
}

.wrapper .admin-navbar .avatar-img {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -2px;
}

.wrapper .admin-navbar .user-name {
  line-height: 20px;
  font-size: 14px;
}

.wrapper .sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #222d32;
  transition: all 0.3s;
}

.wrapper.sidebar-mini .sidebar {
  margin-left: -250px;
}

.wrapper .sidebar .sidebar-header {
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  padding: 0 15px;
  overflow: hidden;
}

.wrapper .sidebar ul.components {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 0;
}

.wrapper .sidebar ul.components p {
  padding: 0 20px;
  color: #8f989c;
  font-size: 11px;
}

.wrapper .sidebar ul.components li a {
  color: #ffffff;
  padding: 12px 20px;
  font-size: 12px;
  display: block;
  border: 3px solid transparent;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.wrapper .sidebar ul.components li a:hover,
.wrapper .sidebar ul.components li a:focus,
.wrapper .sidebar ul.components li a:active,
.wrapper .sidebar ul.components li a.active-menu {
  background: #1d272b;
  border-left: 3px solid #367fa9;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.wrapper .sidebar ul.components li a i {
  margin-right: 5px;
}

.wrapper .content {
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 50px);
  transition: all 0.3s;
}

.wrapper .content .box .box-body {
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 122px);
  transition: all 0.3s;
}

@media(max-width: 768px) {
  .wrapper .sidebar {
    margin-left: -250px;
  }

  .wrapper.sidebar-mini .sidebar {
    margin-left: 0;
  }

  .wrapper.sidebar-mini .content .box,
  .wrapper.sidebar-mini .content .navbar .ml-auto {
    display: none;
  }
}
