.navbar {
  background-color: transparent !important;
  height: 55px;
  color: #fff !important;
}

.navbar-brand.img-fluid {
  width: 15% !important;
}

@media only screen and (max-width: 992px) {
  .navbar-brand.img-fluid {
    width: 25% !important;
  }
}

.active {
  background-color: #0659B2 !important;
}

.navbar .navbar-brand {
  color: #ffffff;
}

.nav-link {
  color: #fff !important;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 12px;
}

.nav-link-scroll {
  color: #fff !important;
  display: block;
  margin: 0.5rem 0.5rem;
  font-size: 12px;
}

.navbar .register-btn {
  padding: 12px 30px !important;
  background: #ffffff;
  border-radius: 22px;
  font-weight: 400;
}

.navbar .signin-btn {
  padding: 12px 30px !important;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  border-radius: 22px;
  font-weight: 400;
}

.register-btn-scroll {
  padding: 12px 30px !important;
  background: #fff;
  color: #0659B2;
  border-radius: 22px;
  font-weight: 400;
}

.signin-btn-scroll {
  padding: 12px 30px !important;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  border-radius: 22px;
  font-weight: 400;
}

@media only screen and (max-width: 960px) {
  
  .nav-link {
    color: #000 !important;
    display: block;
    margin: 0.1rem 1rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #fff;
  }
}

@media(max-width: 780px) {
  .navbar .navbar-collapse {
    background-color: #0659B2 !important;
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    border-top: 1px solid #f9f9f9;
    padding: 20px;
  }

  .navbar .nav-link {
    color: #fff !important;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 15px;
  } 

  .navbar .signin-btn {
    padding: 12px 30px !important;
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 22px;
    font-weight: 400;
  }

  .navbar .register-btn {
    padding: 12px 30px !important;
    background: #fff;
    color: #0659B2;
    border-radius: 22px;
    font-weight: 400;
  }
}
