.claim-container {
  padding: 100px 16px;
}

.claim-container .columns {
  padding: 0;
}

.claim-container .columns .text-small {
  font-size: 14px;
}

.testimony-container .testimony .user-thumb img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #006ba1;
  float: left;
  margin-right: 15px;
}

.testimony-container .testimony .test-descr {
  position: relative;
  top: 6px;
}

@media only screen and (min-width: 990px) {
  .cities-container .col-lg-6,
  .cities-container .col-lg-12 {
    padding: 0 5px !important;
  }
}

.cities-container .city-box .inner {
  margin-top: -70px;
}

.cities-container .city-box img.latest {
  width: 100%;
  height: 410px;
}

.cities-container .city-box img.flat {
  width: 100%;
  height: 210px;
}

.cities-container .city-box img.small {
  width: 100%;
  height: 185px;
}

.cities-container .city-box img {
  object-fit: cover;
  vertical-align: middle;
}

.hero__bgImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/banner/banner-v2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 85vh;
  width: 100%;
}

.hero__content h2 {
  letter-spacing: 0.04em;
  padding-top: 4.5em;
}

.hero__content h6 {
  font-size: 15px;
  line-height: 1.8em;
}

.form__container {
  padding: 0px 0px 4px 0px;
  border-radius: 30px;
  background-color: #fff;
  opacity: 0.8;
}

.hero__content,
.col-md-5,
.col-md-4 {
  padding-right: 0px !important;
}

.custom-select {
  font-size: 10px !important;
}

.hero__content .input-group-text {
  font-size: 14px !important;
  color: #000;
  font-weight: 600;
}

@media only screen and (max-width: 720px) {
  .hero__bgImage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
      url("../../../assets/images/banner/banner-v2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero__content h2 {
    letter-spacing: 0.04em;
    padding-top: 3em;
  }
  .form__container {
    padding: 0px 0px 4px 0px;
    border-radius: 0px !important;
    background-color: #fff;
    opacity: 0.8;
  }
  .hero__content,
  .col-md-5,
  .col-md-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.business__view__hero__bgImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    url("../../../assets/images/banner/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding: 20px 0; 
}

.business__view__hero__bgImage-small {
  background: transparent;
  height: 50px;
}
