footer {
  background: #002762;
}

.filter-box {
  margin-top: 5em;
}

.filter-box .form-control {
  height: 45px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  color: #cccccc;
  text-align: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  line-height: 33px;
  margin-top: 0;
}

.filter-box .css-yk16xz-control {
  height: 45px !important;
}

.text {
  color: #6c757d !important;
  margin: 10px auto !important;
  text-align: center !important;
  font-size: 15px !important;
  max-width: 600px !important;
  position: relative !important;
}

.iconBox {
  width: 50px;
  height: 50px;
  padding: 30px;
  border-radius: 50%;
  background-color: #1fd88b !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text::before {
  content: "" !important;
  display: block !important;
  width: 80px !important;
  height: 2px !important;
  background-color: #6c757d !important;
  position: relative;
  margin-left: 8em !important;
  top: 11px !important;
}

.text::after {
  content: "" !important;
  display: block !important;
  width: 80px !important;
  height: 2px !important;
  background-color: #6c757d !important;
  margin-right: 8em !important;
  margin-top: -9px !important;
}

.filter-box .btn {
  background: #fddd01;
  height: 45px;
  border-radius: 4px;
  font-size: 14px;
  color: #000000;
}

.filter-box .btn:hover,
.filter-box .btn:active,
.filter-box .btn:focus {
  background: #fddd01;
  color: #000000;
}

.list-box {
    border-radius:  22px;
}

.text {
  text-align: justify;
}
.navbar {
  background-color: transparent !important;
  height: 55px;
  color: #fff !important;
}

.navbar-brand.img-fluid {
  width: 15% !important;
}

@media only screen and (max-width: 992px) {
  .navbar-brand.img-fluid {
    width: 25% !important;
  }
}

.active {
  background-color: #0659B2 !important;
}

.navbar .navbar-brand {
  color: #ffffff;
}

.nav-link {
  color: #fff !important;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 12px;
}

.nav-link-scroll {
  color: #fff !important;
  display: block;
  margin: 0.5rem 0.5rem;
  font-size: 12px;
}

.navbar .register-btn {
  padding: 12px 30px !important;
  background: #ffffff;
  border-radius: 22px;
  font-weight: 400;
}

.navbar .signin-btn {
  padding: 12px 30px !important;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  border-radius: 22px;
  font-weight: 400;
}

.register-btn-scroll {
  padding: 12px 30px !important;
  background: #fff;
  color: #0659B2;
  border-radius: 22px;
  font-weight: 400;
}

.signin-btn-scroll {
  padding: 12px 30px !important;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  border-radius: 22px;
  font-weight: 400;
}

@media only screen and (max-width: 960px) {
  
  .nav-link {
    color: #000 !important;
    display: block;
    margin: 0.1rem 1rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #fff;
  }
}

@media(max-width: 780px) {
  .navbar .navbar-collapse {
    background-color: #0659B2 !important;
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    border-top: 1px solid #f9f9f9;
    padding: 20px;
  }

  .navbar .nav-link {
    color: #fff !important;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 15px;
  } 

  .navbar .signin-btn {
    padding: 12px 30px !important;
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 22px;
    font-weight: 400;
  }

  .navbar .register-btn {
    padding: 12px 30px !important;
    background: #fff;
    color: #0659B2;
    border-radius: 22px;
    font-weight: 400;
  }
}

.claim-container {
  padding: 100px 16px;
}

.claim-container .columns {
  padding: 0;
}

.claim-container .columns .text-small {
  font-size: 14px;
}

.testimony-container .testimony .user-thumb img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #006ba1;
  float: left;
  margin-right: 15px;
}

.testimony-container .testimony .test-descr {
  position: relative;
  top: 6px;
}

@media only screen and (min-width: 990px) {
  .cities-container .col-lg-6,
  .cities-container .col-lg-12 {
    padding: 0 5px !important;
  }
}

.cities-container .city-box .inner {
  margin-top: -70px;
}

.cities-container .city-box img.latest {
  width: 100%;
  height: 410px;
}

.cities-container .city-box img.flat {
  width: 100%;
  height: 210px;
}

.cities-container .city-box img.small {
  width: 100%;
  height: 185px;
}

.cities-container .city-box img {
  object-fit: cover;
  vertical-align: middle;
}

.hero__bgImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    url(/static/media/banner-v2.c3f1c439.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 85vh;
  width: 100%;
}

.hero__content h2 {
  letter-spacing: 0.04em;
  padding-top: 4.5em;
}

.hero__content h6 {
  font-size: 15px;
  line-height: 1.8em;
}

.form__container {
  padding: 0px 0px 4px 0px;
  border-radius: 30px;
  background-color: #fff;
  opacity: 0.8;
}

.hero__content,
.col-md-5,
.col-md-4 {
  padding-right: 0px !important;
}

.custom-select {
  font-size: 10px !important;
}

.hero__content .input-group-text {
  font-size: 14px !important;
  color: #000;
  font-weight: 600;
}

@media only screen and (max-width: 720px) {
  .hero__bgImage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
      url(/static/media/banner-v2.c3f1c439.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero__content h2 {
    letter-spacing: 0.04em;
    padding-top: 3em;
  }
  .form__container {
    padding: 0px 0px 4px 0px;
    border-radius: 0px !important;
    background-color: #fff;
    opacity: 0.8;
  }
  .hero__content,
  .col-md-5,
  .col-md-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.business__view__hero__bgImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    url(/static/media/hero.2d1981bb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding: 20px 0; 
}

.business__view__hero__bgImage-small {
  background: transparent;
  height: 50px;
}

.bg-image {
  background-image: url(/static/media/background_light.78723492.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
}

.step-content {
  box-sizing: content-box !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  width: 15rem !important;
  min-width: 15rem !important;
  max-width: 15rem !important;
  padding-top: .5rem !important;
  padding-right: 1rem !important;
}

.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: 14.5rem !important;
  height: 2px;
  /* transform: translate(-100%, -50%); */
  color: #adb5bd;
  background-color: currentColor;
}

@media only screen and (max-width: 1200px) {
  .step-content {
    box-sizing: content-box !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 8rem !important;
    min-width: 8rem !important;
    max-width: 8rem !important;
    padding-top: .5rem !important;
    padding-right: 1rem !important;
  }

  .step-circle::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px;
    width: 7.5rem !important;
    height: 2px;
    /* transform: translate(-100%, -50%); */
    color: #adb5bd;
    background-color: currentColor;
  }
}

@media only screen and (max-width: 576px) {
  .step-content {
    box-sizing: content-box !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 5.5rem !important;
    min-width: 5.5rem !important;
    max-width: 5.5rem !important;
    padding-top: .5rem !important;
    padding-right: 1rem !important;
  }

  .step-circle::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px;
    width: 5rem !important;
    height: 2px;
    /* transform: translate(-100%, -50%); */
    color: #adb5bd;
    background-color: currentColor;
  }
}

.bg-special {
  color: #FD0052;
}

.bg_image_signIn {
  background-image: url(/static/media/background_light.78723492.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.services__section {
  position: relative;
  margin-top: -4em;
}

.services__section p {
  color: #078ece !important;
}

.services__section .img-fluid {
  height: 45px;
}

@media only screen and (max-width: 720px) {
  .services__section {
    margin-top: 1em;
  }
}
.cursor {
  cursor: pointer;
}

/* .navbar {
  background-color: transparent;
  height: 55px;
  color: #fff !important;
}

.navbar.active {
  background-color: #fff;
}

.navbar .button-add {
  font-size: 10px;
  padding: 12px 16px;
} */

/* .navbar-collapse {

} */
/* .nav-link {
  color: #fff !important;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 12px;
}

.nav-link-scroll {
  color: #414141 !important;
  display: block;
  margin: 0.5rem 0.5rem;
  font-size: 12px;
}

.subscribeBox {
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  padding: 0.2em 0.2em 1em 1em;
}

.subscribeBox > input {
  background-color: transparent;
  outline: none;
  outline-width: 0;
  outline-style: none;
  color: #222;
  border: none;
}

.subscribeBox > .btn {
  float: right !important;
  margin: 0.2em 0.2em 0em 0em;
  border-radius: 50%;
}

.footer__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fab {
  width: 24px;
  height: 24px;
  margin: 0px 5px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@media only screen and (max-width: 960px) {
  .nav-link {
    color: #000 !important;
    display: block;
    margin: 0.1rem 1rem;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #fff;
  }
}

@media(max-width: 780px) {
  .navbar .navbar-collapse {
      background: #ffffff;
      position: absolute;
      top: 55px;
      right: 0;
      left: 0;
      border-top: 1px solid #f9f9f9;
      padding: 20px;
  }

  .navbar .button-add,
  .navbar .nav-link .button-add {
    position: relative;
    top: 10px;
    left: -10px;
  }
} */

/* Admin Layout Css */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrapper .admin-navbar {
  background: #3c8dbc;
  height: 50px;
  z-index: 10;
  color: #ffffff;
}

.wrapper .admin-navbar .avatar-img {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -2px;
}

.wrapper .admin-navbar .user-name {
  line-height: 20px;
  font-size: 14px;
}

.wrapper .sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #222d32;
  transition: all 0.3s;
}

.wrapper.sidebar-mini .sidebar {
  margin-left: -250px;
}

.wrapper .sidebar .sidebar-header {
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  padding: 0 15px;
  overflow: hidden;
}

.wrapper .sidebar ul.components {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 0;
}

.wrapper .sidebar ul.components p {
  padding: 0 20px;
  color: #8f989c;
  font-size: 11px;
}

.wrapper .sidebar ul.components li a {
  color: #ffffff;
  padding: 12px 20px;
  font-size: 12px;
  display: block;
  border: 3px solid transparent;
  transition: 0.2s ease-out;
}

.wrapper .sidebar ul.components li a:hover,
.wrapper .sidebar ul.components li a:focus,
.wrapper .sidebar ul.components li a:active,
.wrapper .sidebar ul.components li a.active-menu {
  background: #1d272b;
  border-left: 3px solid #367fa9;
  transition: 0.2s ease-out;
}

.wrapper .sidebar ul.components li a i {
  margin-right: 5px;
}

.wrapper .content {
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 50px);
  transition: all 0.3s;
}

.wrapper .content .box .box-body {
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 122px);
  transition: all 0.3s;
}

@media(max-width: 768px) {
  .wrapper .sidebar {
    margin-left: -250px;
  }

  .wrapper.sidebar-mini .sidebar {
    margin-left: 0;
  }

  .wrapper.sidebar-mini .content .box,
  .wrapper.sidebar-mini .content .navbar .ml-auto {
    display: none;
  }
}

@media only screen and (min-width: 996px) {
  .card-height {
    height: 31vh
  }
  .card-height .img-fluid {
    height: 25vh;
  }
}

@media only screen and (max-width: 992px) {
  .card-height {
    height: 47vh;
    padding: 10px 15px;
  }
}
/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), url(/static/media/Poppins-Regular.aa27b6bd.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Italic'), url(/static/media/Poppins-Italic.583b15db.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Thin'), url(/static/media/Poppins-Thin.c357f4b0.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Thin Italic'), url(/static/media/Poppins-ThinItalic.04ff2542.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraLight'), url(/static/media/Poppins-ExtraLight.84c7f559.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraLight Italic'), url(/static/media/Poppins-ExtraLightItalic.0e15efe2.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Light'), url(/static/media/Poppins-Light.55085b14.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Light Italic'), url(/static/media/Poppins-LightItalic.f8013ec4.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium'), url(/static/media/Poppins-Medium.c91495af.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Medium Italic'), url(/static/media/Poppins-MediumItalic.540729a5.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins SemiBold'), url(/static/media/Poppins-SemiBold.c216913a.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins SemiBold Italic'), url(/static/media/Poppins-SemiBoldItalic.5d1d819f.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Bold'), url(/static/media/Poppins-Bold.9a27401e.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Bold Italic'), url(/static/media/Poppins-BoldItalic.d3238043.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraBold'), url(/static/media/Poppins-ExtraBold.ede5f47d.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins ExtraBold Italic'), url(/static/media/Poppins-ExtraBoldItalic.c10ddd7d.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Black';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Black'), url(/static/media/Poppins-Black.ef8fd862.woff) format('woff');
    }
    

    @font-face {
    font-family: 'Poppins Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Black Italic'), url(/static/media/Poppins-BlackItalic.449b690d.woff) format('woff');
    }
* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: auto;
  font-family: "Poppins Regular";
  font-size: 12px;
  color: #000000;
  background: #f8f8f8;
  overflow-x: hidden;
}

.small {
  font-size: 10px;
}

/** Typograph **/
a,
a:hover,
a:active,
a:focus {
  -webkit-text-decoration-color: none;
          text-decoration-color: none;
  text-decoration: none;
  outline: none;
}

.text-head {
  font-size: 72px;
  font-family: "Poppins Bold";
  line-height: 72px;
}

.text-justify {
  text-align: justify !important;
}

.text-title {
  font-size: 28px;
  font-family: "Poppins Medium";
  word-wrap: break-word;
}

.text-sub-title {
  font-size: 22px;
  font-family: "Poppins Regular";
  line-height: 28px;
  word-wrap: break-word;
}

.text-normal {
  font-size: 15px;
  line-height: calc(14px * 1.5px);
  word-wrap: break-word;
}

.text-small {
  font-size: 12px;
  line-height: calc(10px * 1.5px);
  word-wrap: break-word;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/** font **/

.font-bold {
  font-family: "Poppins Bold";
}

.font-regular {
  font-family: "Poppins Regular";
}

.font-medium {
  font-family: "Poppins Medium";
}

.font-light {
  font-family: "Poppins Light";
}

/** Color **/

.color-blue-dark {
  color: #3c8dbc;
}

.color-grey {
  color: #5e5e5e;
}

.color-white {
  color: #ededed;
}

.color-black {
  color: #000000;
}

/** Background **/

.background-blue {
  background-color: #078ece;
}

.background-grey {
  background-color: #eff3f6;
}

.background-white {
  background-color: #ffffff;
}

/** Input **/

textarea {
  resize: none;
}

.form-control {
  height: 45px !important;
  border-radius: 2px;
  margin: 3px 0;
  font-size: 11px;
  color: #1f1f1f !important;
  border: 1px solid #ededed;
  background: #ffffff;
  font-family: "Poppins Medium";
  box-shadow: none;
  outline: none;
  transition: all 0.2s ease;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  border: 2px solid #3c8dbc !important;
  height: 45px !important;
}

.form-control.phone-input {
  width: 70% !important;
}

.cursor {
  cursor: pointer;
}

.form-control:focus,
.form-control:active {
  border: 2px solid #078ece;
  box-shadow: none;
  outline: none;
  transition: all 0.2s ease;
}

.btn {
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

/** button **/
.btn-primary {
  background: #135465;
  color: #ffffff;
  border-radius: 0;
  border: none;
  outline: none;
  font-family: "Poppins Medium";
  font-size: 12px;
}
.btn-success {
  color: #ffffff;
  border-radius: 0;
  border: none;
  outline: none;
  font-family: "Poppins Medium";
  font-size: 14px;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: #135465;
  border: none;
  outline: none;
  color: #fff;
  transition: 0.2s ease-out;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 3px 7px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}


.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.btn-paggination {
  width: 40px;
  height: 40px;
  border: 2px solid #3b3b3b !important;
  border-radius: 50%;
}

.btn-paggination:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: all !important;
}
