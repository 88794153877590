@media only screen and (min-width: 996px) {
  .card-height {
    height: 31vh
  }
  .card-height .img-fluid {
    height: 25vh;
  }
}

@media only screen and (max-width: 992px) {
  .card-height {
    height: 47vh;
    padding: 10px 15px;
  }
}