.bg-image {
  background-image: url("../../../assets/images/banner/background_light.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
}

.step-content {
  box-sizing: content-box !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  width: 15rem !important;
  min-width: 15rem !important;
  max-width: 15rem !important;
  padding-top: .5rem !important;
  padding-right: 1rem !important;
}

.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: 14.5rem !important;
  height: 2px;
  /* transform: translate(-100%, -50%); */
  color: #adb5bd;
  background-color: currentColor;
}

@media only screen and (max-width: 1200px) {
  .step-content {
    box-sizing: content-box !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 8rem !important;
    min-width: 8rem !important;
    max-width: 8rem !important;
    padding-top: .5rem !important;
    padding-right: 1rem !important;
  }

  .step-circle::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px;
    width: 7.5rem !important;
    height: 2px;
    /* transform: translate(-100%, -50%); */
    color: #adb5bd;
    background-color: currentColor;
  }
}

@media only screen and (max-width: 576px) {
  .step-content {
    box-sizing: content-box !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 5.5rem !important;
    min-width: 5.5rem !important;
    max-width: 5.5rem !important;
    padding-top: .5rem !important;
    padding-right: 1rem !important;
  }

  .step-circle::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px;
    width: 5rem !important;
    height: 2px;
    /* transform: translate(-100%, -50%); */
    color: #adb5bd;
    background-color: currentColor;
  }
}

.bg-special {
  color: #FD0052;
}

.bg_image_signIn {
  background-image: url("../../../assets/images/banner/background_light.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.services__section {
  position: relative;
  margin-top: -4em;
}

.services__section p {
  color: #078ece !important;
}

.services__section .img-fluid {
  height: 45px;
}

@media only screen and (max-width: 720px) {
  .services__section {
    margin-top: 1em;
  }
}
.cursor {
  cursor: pointer;
}
